import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { Row } from "react-bootstrap";
import SectionHeader from "components/SectionHeader";
import PublicationsItem from "components/PublicationsItem";
import PageSection from "components/PageSection";
import "./Publications.scss";

const Publications = ({ className, frontmatter }) => {
    if (!frontmatter) {
        return null;
    }

    const { anchor, header: rootHeader, subheader: rootSubHeader, publications } = frontmatter;

    return (
        <PageSection className={clsx("publications-section", className)} id={anchor}>
            <Row>
                <SectionHeader header={rootHeader} subheader={rootSubHeader} />
            </Row>
            <Row>
                {publications.map(
                    ({ header, imageFileName, fileName, subheader }) => (
                        <PublicationsItem
                            key={header}
                            imageFileName={imageFileName}
                            header={header}
                            subheader={subheader}
                            fileName={fileName}
                        />
                    ),
                )}
            </Row>
        </PageSection>
    );
};

Publications.propTypes = {
    className: PropTypes.string,
    frontmatter: PropTypes.object,
};

Publications.defaultProps = {
    className: null,
    frontmatter: null,
};

export default Publications;
